import React from 'react'
import { AvailableSlotsFormProvider } from '../AvailableSlotsFormProvider'
import { Form } from 'react-final-form'
import { NewForm } from '../../../NewForm'
import { SelectTimeForm } from '../MobileView/SelectTimeModal/SelectTimeForm'
import { StyledModal } from './index.styles'
import { useFormSubmit } from '../../../../atoms/useFormSubmit'
import { useGetInitialValue } from '..'
import { usePsyCardStorage } from '../../usePsyCardStorage'
import { useSendCodeModalContext } from '../../../RegistrationModals/CodeSendModal/hooks/useSendCodeModalContext'
import { useSessionStorage } from '../../../../../youtalk-storybook/src/ui'
import { useFormSubmit as useSubmit } from '../hooks/useFormSubmit'
import { useValidateStorageData } from '../../useValidateStorageData'

export const ShowTimeModal = ({
  psychologist,
  isWizard,
  psychologistUserId,
  hasTimeSelect,
  hide
}) => {
  const submit = useSubmit()

  const CATALOG_FILTERS_STORAGE = 'catalog_filters'
  const [catalogFiltersData] = useSessionStorage(CATALOG_FILTERS_STORAGE, {
    psychologistID: psychologist.id
  })

  const context = useSendCodeModalContext()

  const formSubmit = useFormSubmit({
    catalogFiltersData,
    attempt: Number(context.formData.attempt ?? 0)
  })

  usePsyCardStorage()
  useValidateStorageData(isWizard)

  const initialValues = useGetInitialValue(psychologist)

  return (
    <StyledModal hide={hide}>
      {hasTimeSelect ? (
        <Form
          keepDirtyOnReinitialize
          initialValues={initialValues}
          onSubmit={submit}
        >
          {({ handleSubmit, values }) => (
            <AvailableSlotsFormProvider psychologistUserId={psychologistUserId}>
              <SelectTimeForm
                isModal
                handleSubmit={handleSubmit}
                psychologist={psychologist}
                values={values}
              />
            </AvailableSlotsFormProvider>
          )}
        </Form>
      ) : (
        <NewForm
          isModal
          id={psychologist.id}
          initialValues={{}}
          onSubmit={formSubmit}
        />
      )}
    </StyledModal>
  )
}
