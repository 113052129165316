import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as CheckSVG } from './img/check.svg'
import { JoinBy } from './common/JoinBy'
import { Text } from '../../../youtalk-storybook/src/ui'
import { TooltipForOverflowedText } from './common/TooltipForOverflowedText'

const Title = styled(Text.Average)`
  font-weight: 600;
`

const WorkArea = styled(TooltipForOverflowedText)`
  background-color: #f8f9f9;
  border-radius: 6px;
  padding: 1px 6px;
  gap: 2px;

  ${({ checked }) =>
    checked &&
    css`
      padding: 1px 6px 1px 4px;
      color: #2963a3;
      background-color: #e9f2fd;
    `}
`

const Col = styled.div`
  display: grid;
  grid-template-rows: repeat(4, 20px);
  grid-template-columns: 100%;
  gap: 8px;
`

const WorkAreaParagraph = styled(Text.Small)`
  font-weight: 600;
`

export const Bottom = ({ workAreas }) => (
  <Col>
    <Title>Работает с запросами</Title>
    {workAreas.map((workArea) => (
      <WorkAreaParagraph key={workArea.id}>
        <WorkArea
          checked={Boolean(workArea.checked)}
          tooltip={{
            content: workArea.name
          }}
        >
          {workArea.checked ? (
            <JoinBy>
              <CheckSVG />
              &nbsp;
              {workArea.name}
            </JoinBy>
          ) : (
            <>{workArea.name}</>
          )}
        </WorkArea>
      </WorkAreaParagraph>
    ))}
  </Col>
)
