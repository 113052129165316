import React from 'react'
import createDecorator from 'final-form-focus'
import styled from 'styled-components'
import { AboutHelp } from './Form/AboutHelp'
import { Age } from './Form/Age'
import { AgreeList } from './Form/AgreeList'
import { Agreement } from './Form/Agreement'
import { Button } from '../atoms/Button'
import { Email, InternationalTelephone, Name } from './Form/Input'
import { Form } from 'react-final-form'
import { FormButton } from './Form/FormButton'
import { Promocode, setFormStatePromocode } from './Form/Promocode'
import { PsychologistPlate } from '../molecules/PsychologistPlate'
import { SourceID } from '../atoms/mappers/wizard/enums/sourceId'
import { Timezone } from './Form/Timezone'
import { Title } from '../atoms/Title'
import { formAgeValidate } from '../atoms/FormItems/validators/age'
import { size } from '../constants'
import {
  submit,
  useInitialFormValuesAfterDOMLoad,
  useInitialFormValuesFromParams
} from '../atoms/useFormValues'

const FormGridNameAge = styled.div`
  display: grid;
  grid-template-columns: auto 180px;
  grid-gap: 24px;

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`

const focusOnError = createDecorator()

const subscription = {
  errors: true,
  hasSubmitErrors: true,
  hasValidationErrors: true,
  invalid: true,
  pristine: true,
  submitting: true,
  values: true
}

const useInitialValues = () => {
  useInitialFormValuesFromParams([{ fieldName: 'promo' }])
  useInitialFormValuesAfterDOMLoad([{ fieldName: 'tel', value: '+7' }])
}

const DefaultFieldList = () => (
  <>
    <FormGridNameAge>
      <Name />
      <Age />
    </FormGridNameAge>
    <Email />
    <InternationalTelephone />
    <AboutHelp />
    <Timezone />
    <Promocode />
    <AgreeList />
    <FormButton />
    <Agreement />
  </>
)

const FieldList = () => <DefaultFieldList />

const SelectPsychologistForm = styled(
  ({
    className,
    id,
    handleSubmit,
    restart,
    errors,
    initialValues,
    isModal
  }) => {
    useInitialValues()
    return (
      <form
        noValidate
        className={className}
        onSubmit={submit({ handleSubmit, restart, errors, initialValues })}
      >
        {isModal && (
          <>
            <Title.H4>Заявка</Title.H4>
            {id && <PsychologistPlate id={id} />}
            <FieldList id={id} />
          </>
        )}
        {!isModal && (
          <>
            {id && <PsychologistPlate id={id} />}
            <Title.H4>Заявка</Title.H4>
            <FieldList id={id} />
          </>
        )}
      </form>
    )
  }
)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-shadow: ${(props) =>
    props.isModal ? 'none' : '0px 30px 50px rgba(41, 99, 163, 0.1)'};
  border-radius: 16px;
  background: #fff;
  padding: ${(props) => (props.isModal ? '0px' : '24px')};

  & > ${Button.NewPrimary} {
    width: fit-content;

    @media (max-width: ${size.sm}) {
      width: 100%;
    }
  }

  @media (max-width: ${size.sm}) {
    padding: 16px;
    width: 100%;
  }

  ${PsychologistPlate} {
    margin-bottom: 8px;

    @media (max-width: ${size.sm}) {
      margin-bottom: 0px;
    }
  }
`

export const NewForm = ({ onSubmit, id, initialValues, isModal }) => (
  <Form
    decorators={[focusOnError]}
    initialValues={{
      timezone: 'GMT +3',
      countryCode: 'RU',
      sourceId: SourceID.UC_12XK08,
      ...initialValues
    }}
    isModal={isModal}
    mutators={{
      setFormStatePromocode
    }}
    onSubmit={onSubmit}
    render={({
      handleSubmit,
      values,
      submitting,
      form: { restart },
      errors
    }) => (
      <SelectPsychologistForm
        errors={errors}
        handleSubmit={handleSubmit}
        id={id}
        isModal={isModal}
        restart={restart}
        submitting={submitting}
        values={values}
      />
    )}
    subscription={subscription}
    validate={formAgeValidate}
  />
)
