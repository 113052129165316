import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Badges } from './badjes'
import { Border } from '../../atoms/Border'
import { Bottom } from './Bottom'
import { Button, color, usePopup } from '../../../youtalk-storybook/src/ui'
import { CatalogRegistrationModalContextProvider } from '../RegistrationModals/CatalogRegistrationModal/hooks/useCatalogRegistrationModalContext'
import { Middle } from './Middle'
import { RegistrationModalContextProvider } from '../RegistrationModals/RegistrationModal/hooks/useRegistrationModalContext'
import { SendCodeModalWithContext } from '../RegistrationModals/CodeSendModal'
import { ShowTimeModal } from '../Information/CalendarView/ShowTimeModal'
import { Top } from './Top'
import { trackConsultantStart } from '../../components/amplitude/trackConsultantStart'
import { trackShowTimeOpen } from '../../components/amplitude/trackShowTimeOpen'
import { trackWizardCalendarOpen } from '../../components/amplitude/trackWizardCalendarOpen'
import { useCheckIsWizard } from '../../hooks/useCheckIsWizard'
import { useGetPsychologistAvailableSlotsQuery } from '../Information/CalendarView/query'
import {
  usePropsByContext,
  usePropsByPsychologist
} from './hooks/usePropsByContext'

export const PsychologistNewMiniCardLayout = styled.div`
  height: inherit;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dadada;
  border-radius: 16px;
  background-color: ${color.background};
  position: relative;
`

export const StyledBorder = styled(Border)`
  margin: 16px 0;
`

const MoreDetailsButton = styled(Button.NoBordersBlack)`
  width: 100%;
  margin-top: auto;
  background-color: #eff5fb;
`

const TimeButton = styled(Button.NewPrimary)`
  width: 100%;
  margin-top: auto;
  margin-bottom: 16px;
`

const Buttons = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 24px;
  flex-direction: column;
  bottom: 0;
`

// eslint-disable-next-line max-lines-per-function
export const PsychologistNewMiniCard = ({
  name,
  photo,
  href,
  age,
  experience,
  professions,
  nearestSlot,
  price,
  workAreas,
  english,
  avatarProps,
  moreDetailsButtonProps,
  badges,
  reviews,
  psychologist
}) => {
  const { show: showModal, hide: hideModal, visible: visibleModal } = usePopup()

  const isWizard = useCheckIsWizard()
  const { data: slots } = useGetPsychologistAvailableSlotsQuery({
    variables: {
      filter: {
        id: psychologist.userID || psychologist.user.id
      }
    }
  })

  const hasAvailableSlots = useMemo(
    () => slots?.psychologistAvailableSlots?.items.length > 0 ?? false,
    [slots?.psychologistAvailableSlots?.items]
  )

  const hasTimeSelect =
    psychologist.isActive && psychologist.isAvailable && hasAvailableSlots

  const onShowTimeButtonClick = () => {
    trackShowTimeOpen()
    if (!hasTimeSelect) {
      trackConsultantStart()
    } else {
      trackWizardCalendarOpen()
    }
    showModal()
  }

  return (
    <>
      <SendCodeModalWithContext>
        <CatalogRegistrationModalContextProvider
          isWizard={isWizard}
          psychologist={psychologist}
        >
          <RegistrationModalContextProvider
            isWizard={isWizard}
            psychologist={psychologist}
          >
            <PsychologistNewMiniCardLayout>
              <Top
                age={age}
                avatarProps={avatarProps}
                english={english}
                experience={experience}
                href={href}
                moreDetailsButtonProps={moreDetailsButtonProps}
                name={name}
                photo={photo}
                professions={professions}
                reviews={reviews?.length ?? 0}
              />
              <StyledBorder />
              <Middle nearestSlot={nearestSlot} price={price} />
              <StyledBorder />
              <Bottom workAreas={workAreas} />
              <Buttons>
                <TimeButton onClick={() => onShowTimeButtonClick()}>
                  {hasTimeSelect ? 'Выбрать время' : 'Отправить заявку'}
                </TimeButton>
                <MoreDetailsButton
                  target="_blank"
                  type="link"
                  {...moreDetailsButtonProps}
                >
                  Подробнее
                </MoreDetailsButton>
              </Buttons>
              <Badges badges={badges} />
              {visibleModal && (
                <ShowTimeModal
                  hasTimeSelect={hasTimeSelect}
                  hide={hideModal}
                  isWizard={isWizard}
                  psychologist={psychologist}
                  psychologistUserId={psychologist.userID}
                />
              )}
            </PsychologistNewMiniCardLayout>
          </RegistrationModalContextProvider>
        </CatalogRegistrationModalContextProvider>
      </SendCodeModalWithContext>
    </>
  )
}

export const NewMiniCardWithProps = ({ psychologist }) => {
  const props = usePropsByContext({ psychologist })

  return <PsychologistNewMiniCard {...props} psychologist={psychologist} />
}

export const NewMiniCard = ({ psychologist }) => {
  const props = usePropsByPsychologist({ psychologist })

  return <PsychologistNewMiniCard {...props} psychologist={psychologist} />
}
