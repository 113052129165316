import Modal, { ModalContent } from '../../components/Modal'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { ExternalLink } from '../../atoms/GatsbyLink'
import { Event as GAEvent } from '../../components/GA'
import { ReactComponent as SendFailedSVG } from '../../../static/img/icons/atantionTeenager.svg'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { color } from '../../styles/vars/colors'
import { selectDeviceSizeIsMobile } from '../../state/reducers/deviceSlice'
import { size } from '../../constants'
import { useSelector } from 'react-redux'

const SendFailedIcon = styled(SendFailedSVG)``
const Content = styled.div``
const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const TextAnonymously = styled(Text.Average)`
  color: ${color.text.greyDark};
`

export const AgeLimitWarningPopup = styled(({ className, hide, onOpen }) => {
  const isMobile = useSelector(selectDeviceSizeIsMobile)

  useEffect(() => {
    typeof onOpen === 'function' && onOpen()
  }, [])

  useEffect(() => {
    GAEvent.showAgeWarningInForm()
  }, [])

  return (
    <Modal bodyClassName={className} hide={hide}>
      <Content>
        <SendFailedIcon />
        <TextContent>
          <Title.H3>Пока мы не работаем с теми, кому нет 16 лет</Title.H3>
          <span>
            <Text.Average>
              Если вам нет 16-ти, то рекомендуем обратиться
              <br />
              на бесплатный ресурс{' '}
              <ExternalLink href="https://www.xn--b1agja1acmacmce7nj.xn--80asehdb/">
                Твоя территория
              </ExternalLink>
              <br />
              или позвонить на телефон доверия для детей
              <br />и подростков{' '}
              <ExternalLink href="tel:+78002000122">
                +7 (800) 2000‑122
              </ExternalLink>
            </Text.Average>
            <br />
            <TextAnonymously>Это бесплатно и анонимно</TextAnonymously>
          </span>
        </TextContent>
        <Button.NewPrimary onClick={hide} size={isMobile ? 'medium' : 'large'}>
          Ок, спасибо
        </Button.NewPrimary>
      </Content>
    </Modal>
  )
})`
  && {
    max-width: 504px;

    ${Content} {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;
      gap: 32px;
    }

    @media (max-width: ${size.sm}) {
      ${Title.H3} {
        font-size: 18px !important;
        line-height: 26px !important;
      }

      ${Button.NewPrimary} {
        width: 100%;
      }

      ${ModalContent} {
        padding-top: 72px;
      }
    }
  }
`
