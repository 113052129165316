import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CalendarSVG } from './img/calendar.svg'
import { ReactComponent as MoneySVG } from './img/money.svg'
import { Text } from '../../../youtalk-storybook/src/ui'
import { ReactComponent as UrgentSVG } from './img/urgent.svg'
import { color } from '../../styles/vars/colors'
import { size } from '../../constants'

import './date.utils'

const GreyText = styled(Text.Average)`
  color: #838383;
  white-space: nowrap;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;

  @media (max-width: ${size.lg}) {
    flex-direction: column;
    align-items: start;
  }

  @media (max-width: ${size.md}) {
    flex-direction: row;
    align-items: center;
  }
`

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
`

const TextWithIcon = styled(({ className, children, Icon }) => (
  <Text.Average semiBold className={className}>
    {Icon}
    &nbsp;
    {children}
  </Text.Average>
))`
  font-weight: 600;
  white-space: nowrap;
  color: ${(props) => (props.isUrgent ? color.link.default : 'black')};

  * {
    vertical-align: text-top;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Middle = ({ nearestSlot, price }) => (
  <Col>
    <Row>
      <GreyText semiBold>Ближайшая дата</GreyText>
      <InfoRow>
        <TextWithIcon Icon={<CalendarSVG />} isUrgent={nearestSlot.isUrgent}>
          {nearestSlot.dateTime ? (
            <time dateTime={nearestSlot.datetime}>{nearestSlot.display}</time>
          ) : (
            <span>{nearestSlot.display}</span>
          )}
        </TextWithIcon>
        {nearestSlot.isUrgent && <UrgentSVG />}
      </InfoRow>
    </Row>
    <Row>
      <InfoRow>
        <GreyText semiBold>Стоимость</GreyText>
        <TextWithIcon Icon={<MoneySVG />}>{price}</TextWithIcon>
      </InfoRow>
    </Row>
  </Col>
)
