import dayjs from 'dayjs'
import { Language } from '../../../atoms/mappers/gqlEnums/language'
import { equals, min } from 'rambda'
import { formatPriceRoublesWithPennies } from '../../../atoms/formatters'
import { getCheckDateInDaysRangeCb } from '../../../atoms/date'

const getActualRateByDate = (rates, date = new Date()) => {
  const dateFinder = getCheckDateInDaysRangeCb(dayjs(date))
  const psychologistsRates = rates.find(({ startDate, finishDate }) =>
    equals({ startDate, finishDate }, { startDate: null, finishDate: null })
  )

  const rateByDate = rates.find(dateFinder)
  const currentRate = rateByDate ?? psychologistsRates

  return currentRate
}

const getMinPrice = (rates) => {
  if (!rates?.length) {
    return
  }
  const rate = getActualRateByDate(rates)
  const firstRatePrice = rate.prices[0]

  return rate.prices.reduce(
    (acc, nextPrice) =>
      min(acc.price, nextPrice.price) === nextPrice.price ? nextPrice : acc,
    firstRatePrice
  )?.price
}

export const createMinPriceString = (rates) => {
  const minPrice = getMinPrice(rates)
  const formatted = formatPriceRoublesWithPennies({
    amount: minPrice,
    postfix: 'рублей'
  })

  return `от ${formatted}`
}

const getActualPrice = ({
  rates = [],
  date = new Date(),
  language,
  sessionType
}) => {
  const currentRate = getActualRateByDate(rates, date)

  return currentRate.prices.find(
    (rate) =>
      rate.type === sessionType &&
      (rate.language === language || rate.language === Language.Russian)
  )?.price
}

export const createActualPriceString = ({
  rates = [],
  date,
  language,
  sessionType
}) => {
  const actualPrice = getActualPrice({
    rates,
    date,
    language,
    sessionType
  })

  return formatPriceRoublesWithPennies({
    amount: actualPrice,
    postfix: 'рублей'
  })
}
