import React from 'react'
import styled from 'styled-components'
import { ReactComponent as BadgeMiniStarSVG } from './img/badgeMiniStar.svg'
import { Text } from '../../../youtalk-storybook/src/ui'

const PsychologistMiniBadge = styled(({ className, badge }) => (
  <div className={className}>
    <BadgeMiniStarSVG />
    <Text.Average bold>{badge.name}</Text.Average>
  </div>
))`
  display: flex;
  padding: 0px 8px 0px 4px;
  align-items: center;
  gap: 2px;
  border-radius: 16px;
  background: radial-gradient(
    88.19% 157.3% at 13.94% 13.32%,
    #f9e775 0%,
    #fac507 100%
  );

  ${Text.Average} {
    font-weight: 600;
  }
`

export const Badges = styled(({ className, badges }) => {
  if (!badges) {
    return null
  }

  return (
    <div className={className}>
      {badges.map((badge) => (
        <PsychologistMiniBadge key={badge.id} badge={badge} />
      ))}
    </div>
  )
})`
  position: absolute;
  left: -5px;
  top: -10px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`
