import React from 'react'
import styled from 'styled-components'
import { Avatar } from '../../atoms/Avatar'
import { JoinBy } from './common/JoinBy'
import { Reviews } from '../../molecules/Reviews'
import { Text, size } from '../../../youtalk-storybook/src/ui'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  @media (max-width: ${size.lg}) {
    gap: 12px;
  }

  @media (max-width: ${size.md}) {
    gap: 16px;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const Dot = styled(({ className }) => (
  <svg
    className={className}
    fill="none"
    height="4"
    viewBox="0 0 4 4"
    width="4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2" cy="2" fill="#333333" r="2" />
  </svg>
))`
  margin: 0 1ch;
`

const ExperienceWithAge = ({ age, experience }) => (
  <Text.Average semiBold style={{ whiteSpace: 'nowrap' }}>
    <JoinBy Delimiter={<Dot />}>{[age, `Опыт ${experience}`]}</JoinBy>
  </Text.Average>
)

const Professions = ({ professions }) => (
  <Text.Average semiBold>
    {professions.map((profession, index) => (
      <React.Fragment key={index}>
        {Boolean(index) && <br />}
        {profession}
      </React.Fragment>
    ))}
  </Text.Average>
)

export const Top = ({
  age,
  experience,
  name,
  professions,
  reviews,
  moreDetailsButtonProps,
  avatarProps
}) => (
  <Row>
    <Avatar target="_blank" {...avatarProps} />
    <Col>
      <Professions professions={professions} />
      <Text.Large bold>{name}</Text.Large>
      <ExperienceWithAge age={age} experience={experience} />
      {reviews > 0 && (
        <Reviews
          moreDetailsButtonProps={moreDetailsButtonProps}
          reviewsCount={reviews}
        />
      )}
    </Col>
  </Row>
)
