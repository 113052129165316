import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Tooltip } from '../../../../youtalk-storybook/src/ui'
import { isBrowser } from '../../../atoms/root'

function isEllipsisActive(event) {
  if (!isBrowser) {
    return false
  }
  return event.offsetWidth < event.scrollWidth
}

const Overflow = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
`

export const TooltipForOverflowedText = styled(
  ({ className, children, tooltip = {} }) => {
    const ref = useRef()
    const [isActiveOverflow, setActiveOverflow] = useState()

    useEffect(() => {
      if (ref.current) {
        setActiveOverflow(isEllipsisActive(ref.current))
      }
    }, [ref])

    return (
      <Overflow ref={ref} className={className}>
        {isActiveOverflow ? (
          <Tooltip fixedPlacement="bottom-right" {...tooltip}>
            <span>{children}</span>
          </Tooltip>
        ) : (
          children
        )}
      </Overflow>
    )
  }
)``
